<template>
  <div>
    <p class="mb-1 white--text text-center">
      There are no custom views yet. <br>
      To create a custom view:
    </p>
    <ol>
      <li
        v-for="({ text, icon } = {}, index) in tooltipContentList"
        :key="index">
        {{ text }}
        <v-icon
          v-if="icon"
          small
          color="#fff">
          {{ icon }}
        </v-icon>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'ProjectDetailsExportToTooltipContent',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipContentList: [
        {
          text: 'Add Tags to fixtures',
        },
        {
          text: 'Filter by Tag',
          icon: 'mdi-filter',
        },
        {
          text: 'Save View',
          icon: 'mdi-plus-circle-outline',
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
  li {
    color: var(--lightBlack--base);
    font-style: italic;
    font-weight: 600;
  }
</style>
